import { Component, Mixins } from 'vue-property-decorator';
import Layout from '@/components/layouts/main.vue';
import PageTitle from '@/components/ui/page-title/pageTitle.vue';
import { Action, Getter, Mutation } from 'vuex-class';
import FilterMixin from '@/mixins/filter.mixin';
import BoxHeader from '@/components/ui/box-header/boxHeader.vue';
import { ReportsProductWeeklyRequest } from '@/types/request/reports-request.types';
import { DateTime, Info } from 'luxon';
import {
  DATE_MODE_MONTHLY,
  DATEPICKER_FORMAT,
  FILTER_MONTH_YEAR_FORMAT,
  getPrevMonthsDateRange,
  MONTH_FORMAT
} from '@/helpers/date.helper';
import { ProductWeeklyType } from '@/types/api/reports/product-weekly.type';
import { ProductsGetRequest } from '@/types/request/products-request.types';
import { ProductType } from '@/types/api/products/product.type';
import { BrandType } from '@/types/api/brands/brand.type';
import SalesTacosChart from '@/components/ui/charts/sales-tacos-chart/sales-tacos-chart.vue';
import DatepickerSingle from '@/components/ui/inputs/datepicker-single/datepickerSingle.vue';
import AutocompleteInput from '@/components/ui/inputs/autocomplete-input/autocompleteInput.vue';

const title = 'salesTacosReport.title';

@Component({
  name: 'SalesTacosReport',
  page: {
    title
  },
  components: {
    Layout,
    PageTitle,
    BoxHeader,
    SalesTacosChart,
    DatepickerSingle,
    AutocompleteInput
  },
})
export default class SalesTacosReport extends Mixins(FilterMixin) {
  title = title;
  filter: any = this.initFilter();
  pageFilter: any = {
    brandId: this.$route.query.brandId || null
  }
  useBrandFilter = true;

  @Action('getProductWeeklyReport', { namespace: 'reports' })
  getProductWeeklyReport!: (params?: ReportsProductWeeklyRequest) => Promise<ProductWeeklyType>;

  @Getter('getProductWeeklyReport', { namespace: 'reports' })
  productWeeklyReport!: ProductWeeklyType;

  @Mutation('SET_PRODUCT_WEEKLY_REPORT', { namespace: 'reports' })
  setProductWeeklyReport!: (params: ProductWeeklyType | null) => any;

  @Action('getProducts', { namespace: 'products' })
  getProducts!: (params?: ProductsGetRequest) => Promise<ProductType[]>;

  @Getter('getProducts', { namespace: 'products' })
  products!: ProductType[];

  @Getter('getBrands', { namespace: 'brands' })
  brands!: BrandType[];

  created() {
    this.filter.from = this.$route.query.from || this.startMonthDate;
    this.filter.to = this.$route.query.to || this.endMonthDate;
    this.pageFilter.type = this.$route.query.type || DATE_MODE_MONTHLY;

    if (this.pageFilter.brandId) {
      this.getProductsRequest(this.pageFilter.brandId);
    }
    if (this.filter.from && this.filter.to && this.filter.productId) {
      this.getProductBrandReportRequest();
    }
  }

  beforeDestroy() {
    this.setProductWeeklyReport(null);
  }

  getProductBrandReportRequest() {
    return this.getProductWeeklyReport(this.preparedFilter);
  }

  getProductsRequest(value) {
    this.getProducts({ brandId: value });
  }

  get preparedFilter() {
    return {
      ...this.filter,
      from: DateTime.fromFormat(this.filter.from, DATEPICKER_FORMAT).toFormat(FILTER_MONTH_YEAR_FORMAT),
      to: DateTime.fromFormat(this.filter.to, DATEPICKER_FORMAT).toFormat(FILTER_MONTH_YEAR_FORMAT),
    }
  }

  get startMonthDate() {
    return DateTime.local().setZone('utc').startOf('month').toFormat(DATEPICKER_FORMAT);
  }

  get endMonthDate() {
    return DateTime.local().endOf('month').toFormat(DATEPICKER_FORMAT);
  }

  initFilter() {
    return {
      from: this.$route.query.from || null,
      to: this.$route.query.to || null,
      productId: this.$route.query.productId || null,
      type: DATE_MODE_MONTHLY
    };
  }

  get monthsOptions() {
    return [
      { text: this.$t('salesTacosReport.month'), value: null },
      ...Info.months('long').map(item => ({ text: item, value: item }))
    ];
  }

  get brandOptions() {
    return [
      { text: '---', value: null },
      ...this.brands.map(item => ({ text: item.displayName, value: item.id }))
    ];
  }

  get productOptions() {
    return [
      { text: '---', value: null },
      ...this.products.map(item => ({
        text: `${item.asin || ''} - ${item.displayName || item.name || ''}`,
        value: item.id
      }))
    ];
  }

  get statistics(): any {
    return this.productWeeklyReport?.statistics || null;
  }

  get monthFormat() {
    return MONTH_FORMAT;
  }

  getRanges() {
    const ranges = {};

    for (let i = 0; i < 12; i++) {
      const month = DateTime.local().minus({ months: i });
      const year = month.get('year');
      const label = year !== DateTime.local().get('year') ? `${month.monthLong} ${year}` : month.monthLong;

      ranges[label] = getPrevMonthsDateRange(i, true);
    }

    return ranges;
  }

  onSelectBrand(value) {
    this.getProductsRequest(value);
  }
}
